import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';

import {Outlet} from 'react-router-dom';
import {getUserData} from '../../../../store/actions/userActions';
import {
  convertMUIToQueryBuilder,
  DataGrid,
  FilterPopoverProvider,
  generateJsonAndSql,
  generateJsonFromSql,
  QueryReturnValue,
} from '../../../../ui/data';
import {
  applyDatatableSettings,
  convertAndAppendExistingFilters,
  getGridColumnsSettings,
  getUDFUpdatedSettingsAndColumns,
  hasFilterValue,
  hasQuickFilterValue,
  updateColumnSettings,
} from '../../../../lib/commonTableHelpers';
import {calculateColumnWidth} from '../../../header/helper';

import {isEmpty} from '../../../../lib/utils';
import {useQueryKeys} from '../../../../hooks/useQueryKeys';
import {ViewSettings} from './ViewSettings';
import {
  cleanUpUserContactsData,
  getUserContacts,
  updateViewSettingObject,
  userContactsExportToExcel,
} from '../../../../store/actions/contactsActions';
import {userContactsColumns} from './userContactsColumns';
import UserContactsToolbar from './UserContactsToolbar';
import {PAGE_SIZE, UDF_LABELS_REF} from '../../../../utils/constants';
import {initialQuery} from '../../../../ui/data/query-builder/queryHelper';
import ViewSettingsModal from '../../../common/view-settings/ViewSettingsModal';
import {gridModeTypes} from '../../../../constants/common';
import {
  ColumnSetting,
  IViewSettings,
} from '../../../common/view-settings/interface';
import {useComponentMountStatus} from '../../../../hooks/useComponentMountStatus';
import {shouldResetFilterModel} from '../../../../utils/filterUtils';

export const UserContacts = () => {
  const dispatch = useDispatch<any>();

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);

  const {groupKey} = getUserData();
  const {userKey} = useQueryKeys();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [columns, setColumns] = useState<any[]>(userContactsColumns);

  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(null);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>();
  const [quickFilterValue, setQuickFilterValue] = useState<string>('');
  const [settingsKey, setSettingsKey] = useState<string>('');
  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);
  const udfDetails = useSelector((store: any) => store.usersReducer.udfDetails);

  const isComponentMounted = useComponentMountStatus(cleanUpUserContactsData);

  const {
    contactsSettingId,
    contactsData,
    fetchingContacts,
    contactsViewSettings,
    contactsCount,
  } = useSelector((store: any) => store.contactsReducer);

  /** All Toolbar methods */
  const getQuickFilterValue = (value: string, filterQuery: string | null) => {
    let queryString = filterQuery || '';
    if (value) {
      if (!isEmpty(queryString)) {
        queryString += ' and ';
      }
      queryString += `(ContactName LIKE "%${value}%" OR CustName LIKE "%${value}%")`;
      return queryString;
    }
    return filterQuery || '';
  };

  const getUpdatedSqlAndJson = (
    quickSearchValue: string,
    sql: string | null,
    json: QueryReturnValue['json'] | undefined,
  ) => {
    const updatedSql = getQuickFilterValue(quickSearchValue, sql);
    const updatedJson = updatedSql ? generateJsonFromSql(updatedSql) : json;
    return {updatedSql, updatedJson};
  };

  const loadUserContacts = ({
    sortQuery = [],
    settingKey,
    filterQuery = undefined,
    sqlQuery = null,
    quickSearchValue = '',
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
  }: {
    sortQuery: GridSortModel;
    settingKey: string;
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
    quickSearchValue: string;
    pageSizeParam: number;
    skipParam: number;
  }) => {
    const {updatedSql, updatedJson} = getUpdatedSqlAndJson(
      quickSearchValue,
      sqlQuery,
      filterQuery,
    );

    const options = {
      userKey,
      groupKey,
      settingKey,
      sortQuery,
      filterQuery: updatedJson,
      sqlQuery: updatedSql,
      skip: skipParam,
      pageSize: pageSizeParam,
    };
    dispatch(getUserContacts(options));
    setBestFit(false);
  };

  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  useEffect(() => {
    if (settingsKey) {
      loadUserContacts({
        settingKey: settingsKey,
        sortQuery: sortColumn,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
        quickSearchValue: quickFilterValue,
        skipParam: 0,
        pageSizeParam: paginationModel.pageSize,
      });
      resetFilterModel();
      resetPagination();
    }
  }, [settingsKey, userKey]);

  const fetchUserContacts = (
    json: QueryReturnValue['json'],
    sql: string | null,
    quickSearchValue: string,
  ) => {
    loadUserContacts({
      settingKey: settingsKey,
      sortQuery: sortColumn,
      filterQuery: json,
      sqlQuery: json.rules.length > 0 ? sql : null,
      quickSearchValue,
      skipParam: 0,
      pageSizeParam: paginationModel.pageSize,
    });
    resetPagination();
  };

  /** Use effect to update and set columns based on view settings response */
  useEffect(() => {
    if (
      contactsViewSettings &&
      contactsViewSettings.settingsKey &&
      isComponentMounted
    ) {
      const {updatedViewSettings, columnsToDisplay} =
        getUDFUpdatedSettingsAndColumns({
          viewSettings: contactsViewSettings,
          udfDetails,
          columns: userContactsColumns,
          udfLabel: UDF_LABELS_REF.userContacts,
        });
      applyDatatableSettings(
        updatedViewSettings,
        columns,
        columnsToDisplay,
        undefined,
        setColumns,
        setColumnVisibilityModel,
        setSortColumn,
      );
      const jsonData =
        contactsViewSettings?.filter?.length > 0
          ? generateJsonFromSql(contactsViewSettings?.filter)
          : initialQuery;
      setSettingsKey(contactsViewSettings.settingsKey);
      setCustomFilterSqlValue(contactsViewSettings?.filter || '');
      setCustomFilterJsonValue(jsonData);
    }
  }, [contactsViewSettings?.settingsKey, udfDetails]);
  /**
   * onPageChange is prop set for user-contacts grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadUserContacts({
      settingKey: contactsSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      quickSearchValue: quickFilterValue,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
    });
  };

  /**
   * onSortChange is prop set for user-contacts grid to sorting
   * @param args GridPaginationModel array of object which field and sort properties
   */
  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    loadUserContacts({
      settingKey: contactsSettingId,
      sortQuery: args,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      quickSearchValue: quickFilterValue,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
    });
  };

  /**
   * On Refresh refetch list of activities
   */
  const onRefresh = () => {
    loadUserContacts({
      settingKey: contactsSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      quickSearchValue: quickFilterValue,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };

  /**
   * onFilterChange is prop set for user-contacts grid to filter
   * @param args GridFilterModel array of object which field, value and operator properties
   */
  const onFilterChange = (args: GridFilterModel) => {
    setQuickFilterValue(args.quickFilterValues?.[0] || '');
    if (!hasFilterValue(args) && !hasQuickFilterValue(args)) {
      return;
    }
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);
    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);
    setFilterModel(args);
    fetchUserContacts(json, sql, args.quickFilterValues?.[0] || '');
  };

  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }
    setCustomFilterSqlValue(data.json.rules.length > 0 ? data.sql : null);
    setCustomFilterJsonValue(data.json);
    loadUserContacts({
      settingKey: contactsSettingId,
      sortQuery: sortColumn,
      filterQuery: data.json,
      sqlQuery: data.json.rules.length > 0 ? data.sql : null,
      quickSearchValue: quickFilterValue,
      skipParam: 0,
      pageSizeParam: paginationModel.pageSize,
    });

    resetPagination();
  };

  /**
   * Download user-contacts Excel report
   */
  const exportToExcel = () => {
    const {updatedSql, updatedJson} = getUpdatedSqlAndJson(
      quickFilterValue,
      customFilterSqlValue,
      customFilterJsonValue,
    );
    const options = {
      userKey,
      groupKey,
      settingKey: contactsSettingId,
      sortQuery: sortColumn,
      filterQuery: updatedJson,
      sqlQuery: updatedSql,
      skip,
      pageSize: paginationModel.pageSize,
    };
    dispatch(userContactsExportToExcel(options));
  };

  // Handling best-fit (Column resizing)
  const applyBestFit = () => {
    const bestFitColumnsTemp = columns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        minWidth,
      };
    });
    setBestFit(!bestFit);
    setBestFitColumns(bestFitColumnsTemp);
  };

  const updateTableOnColumnSettingsChange = (
    updatedColumnsList: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === contactsViewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumnsList,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };

  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    contactsViewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );

  return (
    <>
      <Outlet context={{onReload: onRefresh}} />
      <ViewSettingsModal
        viewName="UserContacts"
        mode={gridModeTypes.Contacts}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updateViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <ViewSettings />
      <FilterPopoverProvider>
        <DataGrid
          data-testid="user-contacts-grid"
          disableVirtualization
          columns={bestFit ? bestFitColumns : columns}
          rows={contactsData}
          loading={fetchingContacts}
          rowCount={contactsCount}
          sortModel={sortColumn}
          onSortChange={onSortChange}
          onFilterChange={onFilterChange}
          onPageChange={onPageChange}
          filterModel={filterModel}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          CustomToolbar={UserContactsToolbar}
          disableMultipleColumnsSorting
          customToolbarMethods={{
            exportToExcel,
            onRefresh,
            applyBestFit,
            onViewSettings,
          }}
          disableColumnMenu
          headerFilters
          paginationModel={paginationModel}
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
          getRowId={row => row.uniqueKey}
        />
      </FilterPopoverProvider>
    </>
  );
};
