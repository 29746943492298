import {useRef, useState} from 'react';
import dayjs from 'dayjs';
import {constants} from '../../../constants/common';
import {Checkbox} from '../../../ui/inputs';
import TextField from '../../../ui/inputs/textfield/TextField';
import DateTimePicker from '../../../ui/inputs/date-time-picker/DateTimePicker';
import {isEmpty} from '../../../lib/utils';
import {regExNumeric} from '../../../lib/regex';
import Typography from '../../../ui/displays/typography/Typography';

function CommunicatePayment({
  activityInfo,
  updateActivityInfoState = anyValue => {},
  newActivity,
}) {
  const activityRef = useRef(activityInfo);
  const [paymentAmount, setPaymentAmount] = useState(
    isEmpty(activityInfo?.expectedPayment?.paymentAmount)
      ? Number(0).toFixed(2)
      : Number(activityInfo?.expectedPayment?.paymentAmount).toFixed(2),
  );

  const onValueChange = (value, field) => {
    let tempActivityInfo = {...activityRef.current};
    tempActivityInfo = {
      ...tempActivityInfo,
      expectedPayment: {...tempActivityInfo.expectedPayment, [field]: value},
    };
    activityRef.current = tempActivityInfo;
    updateActivityInfoState(tempActivityInfo);
  };

  const onPaymentAmountChange = e => {
    if (e.target.value.includes('-') || !regExNumeric.test(e.target.value)) {
      return;
    }
    setPaymentAmount(e.target.value);
  };

  const onPaymentAmountBlur = () => {
    const pAmount = Number.isNaN(Number(paymentAmount))
      ? Number(0).toFixed(2)
      : Number(paymentAmount).toFixed(2);
    setPaymentAmount(pAmount);
    onValueChange(Number(pAmount).toFixed(2), 'paymentAmount');
  };

  const onActualAmountChange = e => {
    if (e.target.value.includes('-') || !regExNumeric.test(e.target.value)) {
      return;
    }
    onValueChange(e.target.value, 'actualPaymentAmount');
  };

  const onActualAmountBlur = e => {
    onValueChange(Number(e.target.value).toFixed(2), 'actualPaymentAmount');
  };

  return (
    <div className="card p-3 w-max-content">
      <div className="position-relative border-2-px-solid w-400-px h-max-content ">
        <label
          htmlFor="expected"
          className="position-absolute bg-white px-2 ms-3"
          style={{marginTop: '-10.5px'}}
        >
          {constants.EXPECTED.toLocaleUpperCase()}
        </label>
        <div className="mt-3 px-2 align-items-center">
          <TextField
            id="payment-amount"
            label={constants.PAYMENT_AMOUNT}
            value={paymentAmount}
            onChange={onPaymentAmountChange}
            onBlur={onPaymentAmountBlur}
            disabled={!newActivity}
            margin="dense"
          />
        </div>
        <div className="px-2 mb-2 ">
          <DateTimePicker
            fullWidth
            label={constants.PAY_BY_DATE}
            orientation="portrait"
            yearsPerRow={3}
            slotProps={{
              textField: {
                size: 'small',
              },
            }}
            disabled={!newActivity}
            value={
              !isEmpty(activityInfo?.expectedPayment?.payByDate)
                ? dayjs(activityInfo?.expectedPayment?.payByDate)
                : null
            }
            onChange={value => {
              onValueChange(dayjs(value), 'payByDate');
            }}
            margin="dense"
          />
        </div>
        <div className="mb-2 ps-3">
          <Typography
            className="me-3"
            variant="button"
            sx={{textTransform: 'capitalize'}}
          >
            {constants.CUSTOMER_PROMISED}
          </Typography>
          <Checkbox
            disabled={!newActivity}
            checked={activityInfo?.expectedPayment?.promised === 1}
            onChange={e => onValueChange(e.target.checked ? 1 : 0, 'promised')}
          />
        </div>
      </div>
      <div className="position-relative border-2-px-solid w-400-px h-max-content mt-4">
        <span
          className="position-absolute bg-white px-2 ms-3"
          style={{marginTop: '-10.5px'}}
        >
          {constants.ACTUAL.toLocaleUpperCase()}
        </span>
        <div className="ps-3 mt-2">
          <Typography
            className="me-3"
            variant="button"
            sx={{textTransform: 'capitalize'}}
          >
            {constants.BROKEN}
          </Typography>
          <Checkbox
            disabled
            checked={activityInfo?.expectedPayment?.broken === 1}
            onChange={e => onValueChange(e.checked ? 1 : 0, 'broken')}
          />
        </div>
        <div className="d-flex justify-content-between px-2 align-items-center">
          <TextField
            id="actual-amount"
            label={constants.ACTUAL_AMOUNT}
            disabled
            value={activityInfo?.expectedPayment?.actualPaymentAmount}
            onChange={onActualAmountChange}
            onBlur={onActualAmountBlur}
            margin="dense"
          />
        </div>
        <div className="px-2 mb-2 d-flex align-items-center justify-content-between">
          <DateTimePicker
            fullWidth
            label={constants.ACTUAL_PAY_DATE}
            orientation="portrait"
            yearsPerRow={3}
            slotProps={{
              textField: {
                size: 'small',
              },
            }}
            disabled
            value={
              !isEmpty(activityInfo?.expectedPayment?.actualPaymentDate)
                ? dayjs(activityInfo?.expectedPayment?.actualPaymentDate)
                : null
            }
            onChange={value => {
              onValueChange(dayjs(value), 'actualPaymentDate');
            }}
            margin="dense"
          />
          <i
            className="pi pi-info pi-custom ms-2"
            title={activityInfo?.expectedPayment?.tooltip || ''}
          />
        </div>
      </div>
      <div />
    </div>
  );
}
export default CommunicatePayment;
