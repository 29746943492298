import {MODE_TYPES} from '../../../utils/constants';

/**
 * Build a query string for navigation
 * @param {string} tabTitle tab title where we are navigating
 * @param {object} customerData Customer data to form query string
 * @returns query string to be added to navigation
 */
const createQueryStringForActivity = (tabTitle, customerData, userKey) => {
  let urlQueryString = '';
  if (tabTitle === 'accountDetails' || tabTitle === 'accountDetailsNewTab') {
    urlQueryString = `?CustKey=${customerData.custKey}&AssignedUserKey=${userKey}&SettingID=~All&OEM=LS`;
  } else if (
    tabTitle === 'accountDetailsOrNewActivity' ||
    tabTitle === 'accountDetailsNewTabOrNewActivity' ||
    tabTitle === 'accountDetailsAndNewActivity'
  ) {
    urlQueryString = `?CustKey=${customerData.custKey}&NewCommunicate=true&AssignedUserKey=${userKey}&TaskActKey=${customerData.taskActKey}&SettingID=~All&OEM=LS`;
  }
  return urlQueryString;
};

/**
 * Navigate to activity/Communicate
 * @param {object} communicateProps props to set as state with navigation
 * @param {NavigateFunction} navigate react router navigate function
 * @param {LocationFunction} location react router location function
 */
export const showCommunicate = (communicateProps, navigate, location) => {
  const {search} = location;
  const params = new URLSearchParams(search);
  params.set('activityId', communicateProps.id);

  navigate(`activity?${params.toString()}`, {
    state: {
      ...communicateProps,
      ModeType: communicateProps.ModeType,
      onReload: null,
      from: location,
    },
  });
};

/**
 * method to redirect on click of links on communication grid
 * @param {string} tabTitle tab to which its navigation
 * @param {object} customerData data to be passed for navigation
 * @param {NavigateFunction} navigate react router navigate function
 * @param {LocationFunction} location react router location function
 */
export const redirectToActivityOrAccountOverView = (
  tabTitle,
  customerData,
  navigate,
  location,
  userKey,
) => {
  if (tabTitle === 'accountDetails') {
    const communicateProps = {...customerData, tabName: 'activity'};
    navigate(
      `/account-overview${createQueryStringForActivity(
        tabTitle,
        customerData,
        userKey,
      )}`,
      {
        state: {
          custName: customerData.custName,
          custId: customerData.custId,
          companyId: customerData.companyId,
          ...communicateProps,
        },
        replace: false,
      },
    );
  } else if (tabTitle === 'newActivity') {
    const communicateProps = {
      ...customerData,
      tabName: 'activity',
      onReload: () => {},
      newActivity: true,
      ModeType: MODE_TYPES.activityOnEnquiry,
      // activityList: gridData,
    };
    showCommunicate(communicateProps, navigate, location);
  } else if (
    tabTitle === 'accountDetailsOrNewActivity' ||
    tabTitle === 'accountDetailsAndNewActivity'
  ) {
    const ModeType =
      tabTitle === 'accountDetailsAndNewActivity'
        ? MODE_TYPES.new
        : MODE_TYPES.enquiry;
    const communicateProps = {
      ...customerData,
      tabName: 'activity',
      newActivity: true,
      ModeType,
      //  activityList: gridData,
    };
    navigate(
      `/account-overview${createQueryStringForActivity(
        tabTitle,
        customerData,
        userKey,
      )}`,
      {
        state: {
          custName: customerData.custName,
          custId: customerData.custId,
          companyId: customerData.companyId,
          ...communicateProps,
        },
      },
    );
  } else if (tabTitle === 'allAccountsNewActivity') {
    const communicateProps = {
      ...customerData,
      tabName: 'activity',
      onReload: () => {},
      newActivity: true,
      ModeType: MODE_TYPES.new,
      id: -1,
    };
    showCommunicate(communicateProps, navigate, location);
  }
};

/**
 * Filter and get list which has values
 * @param {*} args list of filtered columns along with values
 * @returns List of items which has value and if operator empty and is empty
 */
export const getFilterItemsIfValueExists = args => {
  return args.items.filter(item => {
    const hasValue = item.value !== undefined && item.value !== null;
    return (
      hasValue || item.operator === 'isEmpty' || item.operator === 'isNotEmpty'
    );
  });
};
