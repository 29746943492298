const initialState = {
  viewSettings: [],
  fetchingViewSettings: false,
  fetchingViewSettingsFailed: false,
  fetchedViewSettings: false,
  accountUsers: [],
  fetchingAccountUsers: false,
  fetchingAccountUsersFailed: false,
  fetchedAccountUsers: false,
  copingViewSettings: false,
  copiedViewSettings: false,
  copyingViewSettingsFailed: false,
  deletingViewSettings: false,
  deletedViewSettings: false,
  deletingViewSettingsFailed: false,
};

const viewSettingsReducer = (
  state = initialState,
  action = {type: '', viewSettings: []},
) => {
  switch (action.type) {
    case 'FETCHING_VIEW_SETTINGS':
      return {
        ...state,
        fetchingViewSettings: true,
        fetchingViewSettingsFailed: false,
        fetchedViewSettings: false,
        copingViewSettings: false,
        copiedViewSettings: false,
        copyingViewSettingsFailed: false,
      };
    case 'FETCHED_VIEW_SETTINGS': {
      return {
        ...state,
        fetchingViewSettings: false,
        fetchedViewSettings: true,
        fetchingViewSettingsFailed: false,
        [action.payload.viewName]: action.payload.viewSettings,
        copingViewSettings: false,
        copiedViewSettings: false,
        copyingViewSettingsFailed: false,
      };
    }
    case 'FETCHING_VIEW_SETTINGS_FAILED':
      return {
        ...state,
        fetchingViewSettings: false,
        fetchingViewSettingsFailed: true,
        fetchedViewSettings: false,
        copingViewSettings: false,
        copiedViewSettings: false,
        copyingViewSettingsFailed: false,
      };
    case 'FETCHING_ACCOUNT_USERS':
      return {
        ...state,
        fetchingAccountUsers: true,
        fetchingAccountUsersFailed: false,
        fetchedAccountUsers: false,
      };
    case 'FETCHED_ACCOUNT_USERS':
      return {
        ...state,
        fetchingAccountUsers: false,
        fetchedViewSettings: true,
        fetchedAccountUsers: false,
        accountUsers: action.accountUsers,
      };
    case 'FETCHING_ACCOUNT_USERS_FAILED':
      return {
        ...state,
        fetchingAccountUsers: false,
        fetchingAccountUsersFailed: true,
        fetchedAccountUsers: false,
      };
    case 'COPYING_VIEW_SETTINGS':
      return {
        ...state,
        copingViewSettings: true,
        copiedViewSettings: false,
        copyingViewSettingsFailed: false,
      };
    case 'COPIED_VIEW_SETTINGS':
      return {
        ...state,
        copingViewSettings: false,
        copiedViewSettings: true,
        copyingViewSettingsFailed: false,
      };
    case 'COPYING_VIEW_SETTINGS_FAILED':
      return {
        ...state,
        copingViewSettings: false,
        copiedViewSettings: false,
        copyingViewSettingsFailed: true,
      };
    case 'DELETING_VIEW_SETTINGS':
      return {
        ...state,
        deletingViewSettings: true,
        deletedViewSettings: false,
        deletingViewSettingsFailed: false,
        fetchingViewSettings: true,
      };
    case 'DELETED_VIEW_SETTINGS':
      return {
        ...state,
        deletingViewSettings: false,
        deletedViewSettings: true,
        deletingViewSettingsFailed: false,
        fetchingViewSettings: false,
        [action.payload.viewName]: action.payload.viewSettings,
      };
    case 'DELETING_VIEW_SETTINGS_FAILED':
      return {
        ...state,
        deletingViewSettings: false,
        deletedViewSettings: false,
        deletingViewSettingsFailed: true,
        fetchingViewSettings: false,
      };
    case 'UPDATE_VIEW_SETTINGS_COLUMN': {
      const {[action.payload.viewName]: viewSettings} = state;
      const index = viewSettings.findIndex(
        viewSetting => viewSetting.settingsKey === action.payload.settingsKey,
      );
      const updatedViewSetting = {
        ...viewSettings[index],
        columnSetting: [...action.payload.columnSetting],
      };
      viewSettings[index] = updatedViewSetting;
      return {
        ...state,
        [action.payload.viewName]: viewSettings,
      };
    }
    case 'ADD_VIEW_SETTINGS': {
      return {
        ...state,
        [action.payload.viewName]: [
          ...state[action.payload.viewName],
          action.payload.viewSettingObject,
        ],
      };
    }
    case 'UPDATE_VIEW_SETTINGS': {
      const {[action.payload.viewName]: viewSettings} = state;
      const index = viewSettings.findIndex(
        viewSetting =>
          viewSetting.settingsKey ===
          action.payload.viewSettingObject.settingsKey,
      );
      if (index !== -1) {
        const newViewSettings = [...viewSettings];
        newViewSettings[index] = action.payload.viewSettingObject;
        return {
          ...state,
          [action.payload.viewName]: newViewSettings,
        };
      }
      return {
        ...state,
        [action.payload.viewName]: viewSettings,
      };
    }
    default:
      return state;
  }
};

export default viewSettingsReducer;
