import {
  GridPaginationModel,
  GridSortModel,
  DataGridPro,
  DataGridProProps,
  GridColumnVisibilityModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import {GridColDef, GridFilterModel, gridClasses} from '@mui/x-data-grid';
import {Theme, alpha, styled} from '@mui/material';
import {ForwardedRef, JSXElementConstructor, forwardRef} from 'react';
import {debounce} from 'lodash';
import CustomPagination from './CustomPagination';
import NoRows from './NoRows';
import QueryBuilder, {QueryReturnValue} from '../query-builder/QueryBuilder';
import {useFilterPopoverContext} from './FilterPopoverContext';
import Popover from '../../utils/popover/Popover';

interface IDataGridProps extends DataGridProProps {
  /**
   * Max number of records to be displayed in one page
   */
  pageSize?: number;
  /**
   * Hide pagination
   */
  hidePagination?: true;
  /**
   * Height
   */
  height?: number | 'auto';
  /**
   * callback method on page change
   */
  onPageChange?: (data: GridPaginationModel) => void;
  /**
   * callback method on page change
   */
  onSortChange?: (data: GridSortModel) => void;
  /**
   * callback method on filter change
   */
  onFilterChange?: (data: GridFilterModel) => void;
  /**
   * callback method on column hide and show
   */
  onColumnVisibilityChange?: (data: GridColumnVisibilityModel) => void;
  /**
   * callback method on column hide and show
   */
  onColumnRowSelect?: (data: GridRowSelectionModel) => void;
  /**
   * Selected Rows
   */
  rowsSelected?: any[];
  /**
   * Custom JSX toolbar to show on header
   */
  CustomToolbar?: JSXElementConstructor<any>;
  /**
   * Custom toolbar methods
   */
  customToolbarMethods?: any;
  /**
   * Allow header level filter
   */
  headerFilters?: boolean;
  /**
   * Show custom filters instead of default filters
   */
  showCustomFilters?: boolean;
  /**
   * Callback method on query filter apply
   */
  onQueryFilterApply?: (data: QueryReturnValue) => void;

  customFilterSqlValue?: any;
}

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPro)(({theme}) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[100],
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },

    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

/**
 * DataGrid component to load table data.
 * More information available here https://mui.com/x/react-data-grid
 */

const DataGrid = forwardRef<HTMLDivElement, IDataGridProps>(
  (
    {
      columns,
      rows,
      hidePagination,
      rowsSelected = [],
      paginationMode = 'server',
      sortingMode = 'server',
      filterMode = 'server',
      pageSize = 10,
      loading = false,
      onPageChange = () => {},
      onSortChange = () => {},
      onFilterChange = () => {},
      onColumnVisibilityChange = () => {},
      onColumnRowSelect = () => {},
      disableRowSelectionOnClick = true,
      keepNonExistentRowsSelected = true,
      CustomToolbar = null,
      height,
      customToolbarMethods = {},
      headerFilters = false,
      showCustomFilters = false,
      onQueryFilterApply = () => {},
      customFilterSqlValue = '',
      ...otherProps
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const {anchorEl, setAnchorEl} = useFilterPopoverContext();

    const handleColumnRowChange = (params: GridRowSelectionModel) => {
      onColumnRowSelect(params);
    };

    function handleFilterModelChange(newFilterModel: GridFilterModel) {
      onFilterChange(newFilterModel);
    }

    const debouncedHandleFilterModelChange = debounce(
      handleFilterModelChange,
      3000,
    );

    const contentHeight = height || 'calc(100vh - 200px)';
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClose = () => {
      setAnchorEl(null);
    };

    const onApplyQueryFilter = (data: QueryReturnValue) => {
      onQueryFilterApply(data);
      handleClose();
    };

    const getQueryBuilderFields = (col: GridColDef<any>[]) => {
      return col.map(c => {
        return {
          name: c.field,
          label: c.headerName || c.field,
          inputType:
            c.type === 'dateTime' ? 'datetime-local' : c.type || 'string',
        };
      });
    };

    return (
      <div
        className=""
        style={{
          height: contentHeight,
          overflow: 'auto',
          width: '100%',
        }}
      >
        {showCustomFilters && (
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                minWidth: '575px',
              },
            }}
          >
            <QueryBuilder
              fields={getQueryBuilderFields(columns)}
              onFilterApply={onApplyQueryFilter}
              customFilterSqlValue={customFilterSqlValue}
              disabled={Boolean(otherProps.filterModel?.items?.length)}
            />
          </Popover>
        )}
        <StripedDataGrid
          {...otherProps}
          ref={ref}
          rows={rows}
          columns={columns}
          rowHeight={40}
          style={{fontSize: '13px'}}
          sortingOrder={['desc', 'asc', null]}
          hideFooterPagination={hidePagination}
          pageSizeOptions={[20, 50, 100, 500]}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {pageSize, page: 0},
            },
          }}
          slots={{
            pagination: CustomPagination,
            toolbar: CustomToolbar,
            noRowsOverlay: NoRows,
            headerFilterMenu: null,
          }}
          slotProps={{
            toolbar: customToolbarMethods,
            filterPanel: {
              filterFormProps: {
                // Customize inputs by passing props
                logicOperatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: {mt: 'auto'},
                  className: 'MuiDataGrid-custom-input',
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: {mt: 'auto'},
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small',
                  },
                },
                deleteIconProps: {
                  sx: {
                    '& .MuiSvgIcon-root': {color: '#d32f2f'},
                  },
                },
              },
              sx: {
                // Customize inputs using css selectors
                '& .MuiDataGrid-filterForm': {
                  p: 2,
                },
                '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                  backgroundColor: (theme: Theme) =>
                    theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                },
                '& .MuiDataGrid-filterFormLogicOperatorInput': {mr: 1},
                '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 125},
                '& .MuiDataGrid-filterFormOperatorInput': {mr: 1},
                '& .MuiDataGrid-filterFormValueInput': {width: 200},
                '& .MuiOutlinedInput-input': {
                  padding: '0 12px',
                  height: '33px',
                },
                '& .MuiOutlinedInput-input:focus': {
                  height: '33px',
                },
                '& .MuiInputAdornment-root': {
                  height: '33px',
                },
                width: '700px',
              },
            },
            baseTextField: {
              variant: 'outlined',
              size: 'small',
            },
          }}
          paginationMode={paginationMode}
          onPaginationModelChange={onPageChange}
          sortingMode={sortingMode}
          onSortModelChange={onSortChange}
          filterMode={filterMode}
          onColumnVisibilityModelChange={onColumnVisibilityChange}
          onFilterModelChange={debouncedHandleFilterModelChange}
          pagination
          disableRowSelectionOnClick={disableRowSelectionOnClick}
          keepNonExistentRowsSelected={keepNonExistentRowsSelected}
          rowSelectionModel={rowsSelected}
          onRowSelectionModelChange={handleColumnRowChange}
          unstable_headerFilters={headerFilters}
          disableColumnFilter={showCustomFilters}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    );
  },
);

export default DataGrid;
