// TODO: Clean up lint errors in july sprint
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {AccountSummary} from './AccountSummary';
import {
  cleanUpStatementsOfAccountData,
  getStatementOfAccount,
  statementOfAccountsExportToExcel,
  updateAccountViewSettingObject,
  updateStatementOfAccountAllRowsSelected,
} from '../../../store/actions/statementOfAccountActions';
import {getUserData} from '../../../store/actions/userActions';
import {ViewSettings} from './ViewSettings';
import {
  DataGrid,
  FilterPopoverProvider,
  QueryReturnValue,
  convertMUIToQueryBuilder,
  generateJsonAndSql,
  generateJsonFromSql,
} from '../../../ui/data';
import {statementOfAccountColumns} from './columns';
import {
  applyDatatableSettings,
  convertAndAppendExistingFilters,
  getCheckBoxSelection,
  getGridColumnsSettings,
  getSelectedRowsByKey,
  getUDFUpdatedSettingsAndColumns,
  hasFilterValue,
  updateColumnSettings,
} from '../../../lib/commonTableHelpers';
import {constants, gridModeTypes} from '../../../constants/common';
import StatementOfAccountToolbar from './StatementOfAccountToolbar';
import {useCustKey} from '../../../hooks/useCustKey';
import InvoiceLines from '../invoice-lines/InvoiceLines';
import {calculateDiscountSum, calculateSum, mappedFilteredRows} from './helper';
import {PAGE_SIZE, TRAN_TYPE, UDF_LABELS_REF} from '../../../utils/constants';
import PaymentPanel from '../PaymentPanel';
import {PaymentErrorDialog} from '../PaymentErrorDialog';
import {
  fetchCurrencies,
  updatePaymentErrorObject,
  updateSelectedCurrency,
} from '../../../store/actions/paymentsCurrenciesActions';
import {isEmpty} from '../../../lib/utils';
import {calculateColumnWidth} from '../../header/helper';
import {Button} from '../../../ui/inputs';
import {
  DiscountAmount,
  OverrideDiscountAmountCheckbox,
  PaymentAmount,
} from './RowComponents';
import {getCountAndTotalAmount} from '../../../utils/paymentUtils';
import {useUserSettings} from '../../../hooks/useUserSettings';
import Menu from '../../../ui/navigation/menu/Menu';
import {Box} from '../../../ui/layouts';
import {Typography} from '../../../ui/displays';
import {initialQuery} from '../../../ui/data/query-builder/queryHelper';
import ViewSettingsModal from '../../common/view-settings/ViewSettingsModal';
import {
  ColumnSetting,
  IViewSettings,
} from '../../common/view-settings/interface';
import {useComponentMountStatus} from '../../../hooks/useComponentMountStatus';
import {shouldResetFilterModel} from '../../../utils/filterUtils';

const defaultFilter = "(balance != '0')";
const jsonDefaultValue: any = generateJsonFromSql(defaultFilter);
interface IStatementCountTotals {
  totalAmount: number;
  invoiceCount: number;
  creditMemoCount: number;
  invoiceSum: number;
  creditMemoSum: number;
  totalDiscount: number;
}

interface GetStatementParams {
  SettingKey: string;
  sortQuery: GridSortModel;
  filterQuery: QueryReturnValue['json'] | undefined;
  sqlQuery: string | null;
  skipParam: number;
  pageSizeParam: number;
}
export const StatementOfAccount = ({
  accountSummary,
  showNewActivityNewIssue,
  allowOnlineCMApplication,
  handleShowToolbars,
  showToolBars,
}: {
  accountSummary: any;
  showNewActivityNewIssue: (selectedInvoice: any) => void;
  allowOnlineCMApplication: number;
  handleShowToolbars: (val: boolean) => void;
  showToolBars: boolean;
}) => {
  const dispatch = useDispatch<any>();
  const [columns, setColumns] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);
  const [isShowInvoiceLinesChecked, setIsShowInvoiceLinesChecked] =
    useState(false);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  /** Payment related states */
  const [showPaymentPanel, setShowPaymentPanel] = useState(false);
  const [showAllAccounts, setShowAllAccounts] = useState(false);
  const [sameCurrencySelectionError, setSameCurrencySelectionError] =
    useState(false);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const selectedCurrency = useSelector(
    (store: any) => store.paymentsCurrenciesReducer?.selectedCurrency,
  );
  const [filterFieldsBlur, setFilterFieldsBlur] = useState(false);
  const [filterFieldsValues, setFilterFieldsValues] = useState({});
  const [isSelectedInvoicesBlur, setIsSelectedInvoicesBlur] = useState(false);
  const [discountErrorPopupClosed, setDiscountErrorPopupClosed] =
    useState(false);
  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(defaultFilter);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>(jsonDefaultValue);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const isComponentMounted = useComponentMountStatus(
    cleanUpStatementsOfAccountData,
  );

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const handleMenuItemClick = (value: boolean) => {
    handleMenuClose();
    handleShowToolbars(value);
  };
  const menuItems = [
    {
      label: constants.ENABLE,
      onClick: () => handleMenuItemClick(true),
    },
    {
      label: constants.DISABLE,
      onClick: () => handleMenuItemClick(false),
    },
  ];

  /** Payment related states */
  const {custKey} = useCustKey();
  const {groupKey} = getUserData();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [skip, setSkip] = useState(0);
  const [isDiscountAmountColumnDisplay, setIsDiscountAmountColumnDisplay] =
    useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [settingsKey, setSettingsKey] = useState<string | null>(null);
  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);
  const {
    settingId,
    statementOfAccounts,
    statementOfAccountsLoading,
    viewSettings,
    statementOfAccountsCount,
  } = useSelector((store: any) => store.statementOfAccountReducer);

  const udfDetails = useSelector((store: any) => store.usersReducer.udfDetails);

  const {features} = useSelector(
    (store: any) => store.usersReducer.userSettings,
  );

  const {userFeatures} = useUserSettings();
  const allowDiscount = userFeatures.find(
    (obj: any) => obj.featureId === constants.ALLOW_DISCOUNT,
  );
  const [statementCountAndTotals, setStatementCountAndTotals] =
    useState<IStatementCountTotals>({
      creditMemoCount: 0,
      totalAmount: 0,
      totalDiscount: 0,
      creditMemoSum: 0,
      invoiceCount: 0,
      invoiceSum: 0,
    });
  const resetPaymentPanelAndRows = ({
    shouldLoadCurrencies,
  }: {
    shouldLoadCurrencies?: boolean;
  }) => {
    if (shouldLoadCurrencies) {
      dispatch(fetchCurrencies({customerKey: custKey, groupKey}));
    }
    setPaymentAmount('');
    setDiscountAmount(0);
    setSelectedAccounts([]);
    dispatch(updateStatementOfAccountAllRowsSelected([]));
  };

  const loadStatementsOfAccount = ({
    sortQuery = [],
    SettingKey,
    filterQuery = undefined,
    sqlQuery = null,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
  }: {
    sortQuery: any[];
    SettingKey: string;
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
    pageSizeParam: number;
    skipParam: number;
  }) => {
    dispatch(
      // @ts-ignore
      getStatementOfAccount({
        custKey,
        groupKey,
        showAllAccounts: showAllAccounts ? 1 : 0,
        SettingKey,
        sortQuery,
        skip: skipParam,
        pageSize: pageSizeParam,
        filterQuery,
        sqlQuery,
      }),
    );
  };
  /** Columns helper functions */

  // On payment amount onBlur
  const setTotalPayment = () => {
    setIsSelectedInvoicesBlur(true);
  };

  /**
   * On change get the amount and update the selected accounts
   * @param id field to which amount is updated
   * @param val updated amount
   */
  const onPaymentAmountChange = (id: string, val: string) => {
    const temp = selectedAccounts.map(obj => {
      if (obj.uniqueKey === id) {
        const isNegativeOrZeroValue = Number(obj.balance) <= 0;
        // Checking for CM, Negative invoices already handled and disabled the text field.
        let amount =
          !isNegativeOrZeroValue && Number(val) > Number(obj.balance)
            ? Number(obj.balance)
            : Number(val);
        amount = Number.isNaN(amount) ? 0 : amount;
        if (amount !== obj.balance) {
          setDiscountAmount(0);
          return {
            ...obj,
            paymentAmount: amount,
            discAmt: 0,
            overrideDiscount: false,
          };
        }
        return {...obj, paymentAmount: amount};
      }
      return obj;
    });
    setSelectedAccounts(temp);
  };

  const onOverrideDiscountChange = (id: string, val: string) => {
    const temp = selectedAccounts?.map((obj: any) => {
      if (obj.uniqueKey === id) {
        setDiscountAmount(obj?.discAmt);
        return {...obj, overrideDiscount: val};
      }
      return obj;
    });
    setSelectedAccounts(temp);
  };

  const onDiscountAmountChange = (id: string, val: number) => {
    const temp = selectedAccounts?.map((obj: any) => {
      if (obj.uniqueKey === id) {
        return {...obj, discAmt: val};
      }
      return obj;
    });
    setSelectedAccounts(temp);
    setDiscountAmount(val);
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  // reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  /**
   *
   * @param params will have cell values
   * @returns Payment amount textfield component
   */
  const renderCellWithFunction = (params: any) => {
    return (
      <PaymentAmount
        values={params}
        setTotalPayment={setTotalPayment}
        onPaymentAmountChange={onPaymentAmountChange}
      />
    );
  };

  const renderCellWithFunctionOverrideDiscountCheckBox = (params: any) => {
    return (
      <OverrideDiscountAmountCheckbox
        values={params}
        discDate={params?.row?.discDate}
        onOverrideDiscountChange={onOverrideDiscountChange}
      />
    );
  };

  const renderCellWhenDiscountAmountChange = (params: any) => {
    return (
      <DiscountAmount
        values={params}
        onDiscountAmountChange={onDiscountAmountChange}
      />
    );
  };
  /**
   * Render cell which requires parent component function as prop cannot be added directly to columns
   * hence updating it by mapping the columns
   */
  const updatedColumns = bestFit
    ? bestFitColumns
    : columns.map(column => {
        if (column.field === 'paymentAmount') {
          return {
            ...column,
            renderCell: (params: any) => renderCellWithFunction(params),
          };
        }
        // Need to hide this column
        // if (column.field === 'overrideDiscount') {
        //   return {
        //     ...column,
        //     renderCell: (params: any) =>
        //       renderCellWithFunctionOverrideDiscountCheckBox(params),
        //   };
        // }

        if (column.field === 'discAmt') {
          return {
            ...column,
            renderCell: (params: any) =>
              renderCellWhenDiscountAmountChange(params),
          };
        }

        return column;
      });

  const handleGetAllStatements = (
    filterQuery: QueryReturnValue['json'],
    sqlQuery: string | null,
  ) => {
    const params: GetStatementParams = {
      SettingKey: settingId,
      sortQuery: sortColumn,
      filterQuery,
      sqlQuery,
      skipParam: 0,
      pageSizeParam: paginationModel.pageSize,
    };
    loadStatementsOfAccount(params);
    resetPagination();
  };

  useEffect(() => {
    const hasDiscountFeature = features?.some(
      (obj: any) =>
        obj.featureId === constants.ALLOW_DISCOUNT && obj.featureValue === 1,
    );
    setIsDiscountAmountColumnDisplay(!!hasDiscountFeature);
  }, [features]);

  useEffect(() => {
    if (settingsKey) {
      loadStatementsOfAccount({
        SettingKey: settingsKey,
        sortQuery: sortColumn,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
        skipParam: 0,
        pageSizeParam: paginationModel.pageSize,
      });
      setPaymentAmount('0');
      setDiscountAmount(0);
      setSelectedAccounts([]);
    }
    resetFilterModel();
    resetPagination();
    resetPaymentPanelAndRows({shouldLoadCurrencies: true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custKey, showAllAccounts, settingsKey]);

  /** Use effect to update and set columns based on view settings response */
  useEffect(() => {
    if (viewSettings && viewSettings.settingsKey && isComponentMounted) {
      const {updatedViewSettings, columnsToDisplay} =
        getUDFUpdatedSettingsAndColumns({
          viewSettings,
          udfDetails,
          columns: statementOfAccountColumns(isDiscountAmountColumnDisplay),
          udfLabel: UDF_LABELS_REF.statementOfAccounts,
          alternateUDFKey: 'invudf',
        });
      applyDatatableSettings(
        updatedViewSettings,
        columns,
        columnsToDisplay,
        undefined,
        setColumns,
        setColumnVisibilityModel,
        setSortColumn,
      );
      const jsonData =
        viewSettings?.filter?.length > 0
          ? generateJsonFromSql(viewSettings?.filter)
          : initialQuery;

      setSettingsKey(viewSettings.settingsKey);
      setCustomFilterSqlValue(viewSettings?.filter);
      setCustomFilterJsonValue(jsonData);
    }
  }, [viewSettings?.settingsKey, udfDetails]);

  /** Use effect to set payment sum based on rows updated */
  useEffect(() => {
    const {
      totalAmount,
      creditMemoCount,
      creditMemoSum,
      invoiceCount,
      invoiceSum,
      totalDiscount,
    } = getCountAndTotalAmount({
      allowDiscount: allowDiscount?.featureValue,
      groupKey,
      paymentAmount,
      selectedInvoicesParam: selectedAccounts,
      allowOnlineCMApplication,
    });
    setStatementCountAndTotals({
      totalAmount,
      creditMemoCount,
      creditMemoSum,
      invoiceCount,
      invoiceSum,
      totalDiscount,
    });
    if (selectedAccounts.length > 0) {
      const sum =
        creditMemoCount > 0 && Math.abs(creditMemoSum) > 0
          ? totalAmount
          : calculateSum(selectedAccounts);
      const discountAmountTotal = isDiscountAmountColumnDisplay
        ? calculateDiscountSum(selectedAccounts)
        : 0.0;
      setDiscountAmount(discountAmountTotal);
      setPaymentAmount(sum);
      dispatch(updatePaymentErrorObject({}));
      selectedAccounts.forEach(x => {
        if (
          x.currId?.toUpperCase() !== selectedCurrency?.toUpperCase() &&
          !isEmpty(selectedCurrency?.toUpperCase())
        ) {
          dispatch(updateSelectedCurrency(''));
          return null;
        }
        return null;
      });
    } else {
      setPaymentAmount('');
      setDiscountAmount(0);
    }
    setIsSelectedInvoicesBlur(true);
    if (creditMemoCount === 0) {
      setDiscountErrorPopupClosed(false);
    }
  }, [selectedAccounts]);

  const closePaymentPanelOnPaymentMade = () => {
    dispatch(updateSelectedCurrency(''));
    setPaymentAmount('0');
    setDiscountAmount(0);
    setSelectedAccounts([]);
    loadStatementsOfAccount({
      SettingKey: settingId,
      sortQuery: sortColumn,
      filterQuery: jsonDefaultValue,
      sqlQuery: defaultFilter,
      skipParam: 0,
      pageSizeParam: 20,
    });
  };

  /** Communication grid props method */

  /**
   * onPageChange is prop set for communication grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    // Prevent api call and resetting when page size changed
    if (args.page !== paginationModel.page) {
      resetPaymentPanelAndRows({shouldLoadCurrencies: false});
    }

    loadStatementsOfAccount({
      SettingKey: settingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: pageSkip,
      pageSizeParam: args.pageSize,
    });
  };

  /**
   * onSortChange is prop set for communication grid to sorting
   * @param args GridPaginationModel array of object which field and sort properties
   */
  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    loadStatementsOfAccount({
      SettingKey: settingId,
      sortQuery: args,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };

  // Validate multiple currency and display pop up when multiple currency selected;
  const validateMultipleCurrency = (selectedRows: any) => {
    if (!selectedAccounts[0]) {
      return;
    }
    const allSameCurrencies = selectedRows.every((element: any) => {
      return element.currId === selectedAccounts[0]?.currId;
    });
    if (!allSameCurrencies && showPaymentPanel)
      setSameCurrencySelectionError(true);
  };

  /**
   * onColumnRowSelect Update state on checkbox selection
   * @param newSelectionModel is selected row object
   */
  const onColumnRowSelect = (newSelectionModel: any) => {
    const selectedRowsObject = getSelectedRowsByKey(
      newSelectionModel,
      statementOfAccounts,
      'uniqueKey',
    ).filter(x => !isEmpty(x));
    let filteredRows = selectedRowsObject.length
      ? selectedRowsObject.filter(
          (row: any) =>
            row?.tranType?.toLocaleLowerCase() !== TRAN_TYPE.PP.toLowerCase(),
        )
      : selectedRowsObject;
    filteredRows = mappedFilteredRows(filteredRows, statementOfAccounts);
    const updatedArray = filteredRows.map(mainObj => {
      const replacementObj = selectedAccounts.find(
        (rep: any) => rep.uniqueKey === mainObj.uniqueKey,
      );
      return replacementObj || mainObj;
    });
    setSelectedAccounts(updatedArray);

    // @ts-ignore
    dispatch(updateStatementOfAccountAllRowsSelected(filteredRows));
    if (filteredRows.length === 0) {
      // Reset payment related information
      setPaymentAmount('');
    }

    if (
      filteredRows.length === 1 &&
      filteredRows[0]?.currId?.toUpperCase() !== selectedCurrency?.toUpperCase()
    ) {
      dispatch(updateSelectedCurrency(filteredRows[0].currId));
    } else if (!filteredRows.length) {
      dispatch(updateSelectedCurrency(''));
    }

    // Display pop-up when select multiple currency and try to make payment;
    validateMultipleCurrency(updatedArray);
  };

  /** All Toolbar methods */

  /**
   * onFilterChange is prop set for communication grid to filter
   * @param args GridFilterModel array of object which field, value and operator properties
   */
  const onFilterChange = (args: GridFilterModel) => {
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);
    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);

    setFilterModel(args);
    if (hasFilterValue(args)) {
      if (
        (Array.isArray(args.items.values) && args.items.values.length === 0) ||
        (Array.isArray(args.items) && args.items.length === 0)
      ) {
        resetPaymentPanelAndRows({shouldLoadCurrencies: false});
      }

      const currency = args.items.find(
        item => item.field.toLowerCase() === 'currid',
      );
      if (!isEmpty(currency?.value)) {
        dispatch(updateSelectedCurrency(currency?.value?.toUpperCase()));
      } else if (selectedAccounts.length === 1) {
        dispatch(
          updateSelectedCurrency(selectedAccounts[0].currId?.toUpperCase()),
        );
      } else {
        dispatch(updateSelectedCurrency());
      }

      handleGetAllStatements(json, json.rules.length > 0 ? sql : null);
    }
  };

  /**  Adding this new method for external filter ,When we filter data from other component 
   it will filter data and communicate to current data grid  */
  const setExternalFilterWithDataGrid = (args: GridFilterModel) => {
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const {json, sql} = generateJsonAndSql(queryBuilderQuery);
    setCustomFilterSqlValue(sql);
    setCustomFilterJsonValue(json);

    if (hasFilterValue(args)) {
      handleGetAllStatements(json, sql);
      resetPaymentPanelAndRows({shouldLoadCurrencies: false});
    }
  };

  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }
    setCustomFilterSqlValue(data.sql);
    setCustomFilterJsonValue(data.json);
    handleGetAllStatements(
      data.json,
      data.json.rules.length > 0 ? data.sql : null,
    );
  };

  /**
   * Download Excel report
   */
  const exportToExcel = () => {
    dispatch(
      // @ts-ignore
      statementOfAccountsExportToExcel({
        groupKey,
        custKey,
        showAllAccounts,
        SettingKey: settingId,
      }),
    );
  };

  /**
   * On Refresh refetch list of activities
   */
  const onRefresh = () => {
    loadStatementsOfAccount({
      SettingKey: settingId,
      sortQuery: sortColumn,
      //  globalFilterQuery: globalFilter || defaultFilter,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };

  /**
   * On new activity clicked on toolbar
   * If accounts are selected we pass the data to new activity
   */
  const onNewActivity = () => {
    const taggedPmts: any[] = [];
    const taggedInvoices: any[] = [];
    if (selectedAccounts && selectedAccounts.length > 0) {
      selectedAccounts.forEach(({tranType, invcKey, custPmtKey}) => {
        if (
          tranType?.toLocaleLowerCase() === TRAN_TYPE.CR.toLocaleLowerCase() ||
          tranType?.toLocaleLowerCase() === TRAN_TYPE.RV.toLocaleLowerCase()
        ) {
          taggedPmts.push(custPmtKey);
        } else {
          taggedInvoices.push(invcKey);
        }
      });
    }
    showNewActivityNewIssue({taggedPmts, taggedInvoices});
  };

  /**
   * On new payment clicked
   */
  const onPayment = () => {
    const allSameCurrencies = selectedAccounts.every(element => {
      return element.currId === selectedAccounts[0].currId;
    });
    if (allSameCurrencies) {
      setShowPaymentPanel(true);
      setSameCurrencySelectionError(false);
    } else {
      setSameCurrencySelectionError(true);
    }
  };
  /** View setting prop callbacks */

  const showInvoiceLines = (value: boolean) => {
    setIsShowInvoiceLinesChecked(value);
  };

  const applyBestFit = () => {
    const bestFitColumnsTemp = columns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        minWidth,
      };
    });
    setBestFit(!bestFit);
    setBestFitColumns(bestFitColumnsTemp);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const updateTableOnColumnSettingsChange = (
    updatedColumnsList: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === viewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumnsList,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };

  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    viewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );

  return (
    <Box marginTop={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h4"
          fontWeight={500}
          fontSize="24px"
          style={{color: '#0F3044'}}
        >
          {constants.STATEMENT_OF_ACCOUNTS}
        </Typography>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          items={menuItems}
        />
        <Button onClick={handleMenuOpen} sx={{padding: '0 0 0 32px'}}>
          <i className="pi pi-setting-grey" />
        </Button>
      </Box>
      <ViewSettingsModal
        viewName="AccountOverview"
        mode={gridModeTypes.StatementOfAccount}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updateAccountViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <div className="d-flex align-items-center justify-content-between">
        <ViewSettings
          showInvoiceLines={showInvoiceLines}
          isShowInvoiceLinesChecked={isShowInvoiceLinesChecked}
          isInvoicesSelected={selectedAccounts.length > 0}
          groupKey={groupKey}
          custKey={custKey}
          onAllAccountsChange={value => setShowAllAccounts(value)}
          showAllAccounts={showAllAccounts}
        />
        <AccountSummary accountSummary={accountSummary} />
      </div>
      <div className="w-100 position-relative my-3">
        {showPaymentPanel && (
          <PaymentPanel
            showPaymentPanel={showPaymentPanel}
            custKey={custKey}
            groupKey={groupKey}
            setShowPaymentPanel={setShowPaymentPanel}
            showPaymentInfo={showPaymentInfo}
            setShowPaymentInfo={setShowPaymentInfo}
            selectedCurrency={selectedCurrency}
            statementOfAccountsSelectedRows={selectedAccounts}
            paymentAmount={paymentAmount}
            // @ts-ignore
            discountAmount={discountAmount}
            showAllAccounts={showAllAccounts}
            // @ts-ignore
            setFilterFieldsValues={setFilterFieldsValues}
            // @ts-ignore
            setFilterFieldsBlur={setFilterFieldsBlur}
            statementOfAccountsObj={statementOfAccounts}
            isSelectedInvoicesBlur={isSelectedInvoicesBlur}
            setIsSelectedInvoicesBlur={setIsSelectedInvoicesBlur}
            filterFieldsValues={filterFieldsValues}
            setExternalFilterWithDataGrid={setExternalFilterWithDataGrid}
            allowDiscount={allowDiscount}
            filterModel={filterModel}
            statementCountAndTotals={statementCountAndTotals}
            allowOnlineCMApplication={allowOnlineCMApplication}
            closePaymentPanelOnPaymentMade={closePaymentPanelOnPaymentMade}
          />
        )}
      </div>
      <FilterPopoverProvider>
        <DataGrid
          height={600}
          disableVirtualization
          columns={updatedColumns}
          rows={statementOfAccounts}
          loading={statementOfAccountsLoading}
          rowCount={statementOfAccountsCount}
          checkboxSelection={getCheckBoxSelection(viewSettings?.columnSetting)}
          sortModel={sortColumn}
          onSortChange={onSortChange}
          onFilterChange={onFilterChange}
          filterModel={filterModel}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          rowsSelected={selectedAccounts.map(item => item.uniqueKey)}
          onColumnRowSelect={onColumnRowSelect}
          CustomToolbar={showToolBars ? StatementOfAccountToolbar : undefined}
          disableMultipleColumnsSorting
          customToolbarMethods={
            showToolBars && {
              exportToExcel,
              onRefresh,
              onNewActivity,
              onPayment,
              applyBestFit,
              onViewSettings,
            }
          }
          onPageChange={onPageChange}
          getRowId={row => row.uniqueKey}
          headerFilters
          paginationModel={paginationModel}
          editMode="row"
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
        />
      </FilterPopoverProvider>
      {isShowInvoiceLinesChecked && (
        <InvoiceLines selectedRowForInvoiceLines={selectedAccounts} />
      )}
      {sameCurrencySelectionError && (
        <PaymentErrorDialog
          errorMessage={constants.PAYMENT_MULTIPLE_CURRENCY_ERROR}
          onClose={() => setSameCurrencySelectionError(false)}
        />
      )}
    </Box>
  );
};
